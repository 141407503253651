import request from '@/utils/request'

export function fetchList(query) {
    return request({
      url: 'marque',
      method: 'get',
      params: query
    })
  }
  
  export function update(data) {
    return request({
      url: 'marque/update',
      method: 'post',
      data
    })
  }