<template>
    <div v-loading="loading" class="table-area wrapper-trading" style="padding: 10px">
      <div class="table-top">
      <el-row>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <h4 class="heading">Marque</h4>
        </el-col>
    
      </el-row>
      </div>
      <div >
        <el-form
        :model="formData"
        ref="refForm"
        label-position="top"
        :status-icon="true"
        >
          <el-row>
            <el-form-item
            :error="formErrors.name"
            label="Marque"
            prop="marque"
            
            >
            <el-input v-model="formData.marque" ></el-input>
            
            </el-form-item>
          </el-row>
          <el-row>
            
            <el-button type="info" @click="submit()">Save</el-button>
          </el-row>
    
        </el-form>
        
      </div>
        

        
  
    </div>
  </template>
  
  <script>
  import { fetchList , update} from "@/api/marque";
  import { mapGetters } from "vuex";
  
  
  
  export default {
    name: "ListPaymentRequest",
    components: {},
    data() {
      return {
        loading: false,
        search: "",
        listDataTemp: null,
        formData: {
          marque:""
        },
        formErrors: [],
        listQuery: {
        },
      };
    },
    computed: {
      ...mapGetters(["getRoleSlug"]),
    },
    created() {
      this.getList();
    },
    methods: {
      handleReset() {
        this.getList();
      },
      // //////////////////
      // GET LIST DATA
      // //////////////////
      getList() {
        //this.loading = true;
        fetchList(this.listQuery).then((response) => {
            this.formData.marque = response.data.data.data;
            this.loading = false;
        });
      },
      submit() {
        this.loading = true;
        update(this.formData)
            .then((response) => {
            if (response.data.success === true) {
                this.loading = false;
                this.flashSuccess(response.data.message);
                this.getList();
            } else {
                this.formErrors = this.validationErrors(response.data.message);
            }
            this.loading = false;
            })
            .catch((err) => {
            this.$message.error(err);
            });
      },
  
      
  }
}
  </script>
  